import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import Layout from "../components/layout";
import Header from "../components/header/header";
import {useBreakpoint} from "gatsby-plugin-breakpoints";
import parse from "html-react-parser";

const query = graphql`
	query {
		strapiPageListeDesFormationsSurLaPedagogiePnl {
            id
			title
			slug
			seo {
			    id
				metaDescription
				metaTitle
			}
            formations {
				id
                title
                events {
				    id
                    date
                    location
                    link
                    instructor
                    dateStart
                    dateEnd
              }
            }
		}
	}
`;

const ListeDesFormationsSurLaPedagogiePnl = () => {
    const data = useStaticQuery(query);
    const page = data.strapiPageListeDesFormationsSurLaPedagogiePnl;

    const today = new Date();
    for (const formation of page.formations) {
        //Remove dateEnd older than today
        const filterByExpiration = arr => arr.filter(({ dateEnd }) => new Date(dateEnd.replace(/-/g, "/")) > today);
        formation.events = filterByExpiration(formation.events);

        //Order by dateStart
        formation.events.sort(function(a,b){
            return a.dateStart.localeCompare(b.dateStart);
        })
    }

    const breakpoints = useBreakpoint();

    return (
        <Layout seo={page.seo}>
            <div className={page.slug}>
                <Header title={page.title}></Header>
                <div
                    className={`container`}
                    style={{marginTop: `${breakpoints.largeUp ? `5rem` : ``}`}}
                >
                    {page.formations.map((formation, index) => (
                        <div key={formation.id}>
                            <h2 className="subtitle">{formation.title}</h2>
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
                                {formation.events.map((event, index) => (

                                    <div className="shape" key={event.id}>
                                        <div className="shape__content">
                                            <h3 className="shape__content__title">Lieu</h3>
                                            <div>{event.location}</div>
                                            <h3 className="shape__content__title">Date</h3>
                                            <div>{parse(event.date)}</div>
                                            {event.instructor && event.instructor.length > 0 &&
                                                <>
                                                    <h3 className="shape__content__title">Formateur</h3>
                                                    <div>{event.instructor}</div>
                                                </>
                                            }
                                        </div>
                                        <div className="button-action">
                                            <a href={event.link} target="_blank" rel="noopener">
                                                <button className="btn btn--green">En savoir plus</button>
                                            </a>
                                        </div>
                                    </div>

                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    );
};

export default ListeDesFormationsSurLaPedagogiePnl;
